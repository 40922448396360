module.exports={
  "encoding": 'utf-8',
  "add_original_files_to_archive": false,
  "add_index_to_archive": false,
  "archive_format": "zip",
  "save_archive": true,
  "include_associated_text_files_in_proof": false,
  "include_standalone_text_files_in_proof": false,
  "generate_qrcode": false,
  "ipfs": {
    "baseUrl": "https://ipfs.io/"
  },
  "ethswarm": {
    "uploadArchive": false,
    "uploadEncrypted": false,
    "baseUrl": "https://gateway.ethswarm.org/"
  }
}
